import React from 'react';
import PropTypes from 'prop-types';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Collapse,
    Box,
    Button,
    ButtonGroup,
    Typography,
    IconButton

} from "@mui/material";

import { useTheme } from '@mui/material/styles';

import FirstPageIcon from '@mui/icons-material/FirstPage';

import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import styled from "styled-components";
import API from "../../../api/api";

import DropDown from "../../../uiElements/Dropdown";
import {StreamType} from "./InspectReports";

// const useStyles1 = makeStyles((theme) => ({
//     root: {
//         flexShrink: 0,
//         marginLeft: theme.spacing(2.5),
//     },
// }));

export function TablePaginationActions(props) {
    // const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        // <div className={classes.root}>
        <div>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

/**
 * Creates the Table to show
 * @param reports
 * @returns {[]}
 */
const getTableRows = (reports) => {
    // console.log(reports)

    let rows = [];
    reports.map((report) => {
        rows.push({
            id: report.id,
            num_of_errors: report.num_of_errors,
            platform: report.platform,
            timestamp: report.timestamp,
            uploader: report.user.email,
            upload_type: report.upload_type,
            report_status: report.report_status,
            file_infos: report.file_infos,
            error_report: report.error_report,
            rule_engine_message: report.rule_engine_message,
        });
    })
    rows.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

    return rows;
}

const useStyles2 = {
    table: {
        minWidth: 500,
    },
};

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         // backgroundColor: "#d3d3d3",
//         color: "#000000",
//         fontWeight: "bold",
//     },
//     body: {
//         fontSize: 14,
//
//     },
// }))(TableCell);

/**
 *
 * @type {StyledComponent<"button", AnyIfEmpty<DefaultTheme>, {}, never>}
 */
const InspectButton = styled.button`
  
  border: 1px solid white;
  cursor: pointer;
  //border: unset;
  line-height: 2;
  min-width: max-content;
  z-index: 1;
  //margin-left: 6px;
  background-color: #0677a1;
  color: ${props => (props.error ? "red": (props.disabled ? "black": "white"))};
  
  padding: 2px 12px 2px 12px;
  
  :disabled, :hover {
    background-color: #3498DB;
    color: ${props => (props.error ? "red": (props.disabled ? "black": "white"))};
  }
  
  >span{
    padding-left: 6px;
  }
  
  :disabled{
    background-color: lightgray;
    //color: black;
    cursor: auto;
  }
  
    
`;

const StatusDot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;

  //From Java
  &.READY_FOR_PROCESS {
    background-color: #bbb;
  }
  
  //From Python
  &.PROCESSING {
    background-color: rgb(248, 232, 2);
  }
  //From Python
  &.DONE {
    background-color: rgb(80, 248, 2);
  }
  //From Java
  &.NOT_READY_FOR_PROCESS {
    background-color: rgb(248, 117, 2);
  }

  //Not often but, when no Files in GridFS where uploaded (e.g disabled Storage-Feature)
  //From Java
  &.INTERNAL_ERROR {
    background-color: rgb(248, 2, 2);
  }

  //From Python
  &.ERROR {
    background-color: rgb(248, 2, 2);
  }
  
`;

const UploadTypeTag = styled.div`
    :before{
        content: "#";
    }
    background-color: ${props => (props.type == "WEB" ? "lightblue" : (props.type == "API" ? "lightgreen" : "") )};
    line-height: 2;
    border-radius: 5px;
    width: 40px;
    font-size: 0.85em;
    padding: 0 2px;
`;

export default function CustomPaginationActionsTable(props) {

    let api = new API();

    //Generating Rows for Table
    let rows = getTableRows(props.uploads)

    const classes = useStyles2;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="right"></TableCell>
                        {/*<TableCell>Comm_Upload Id</StyledTableCell>*/}
                        {/*<TableCell align="center">Report Id</StyledTableCell>*/}
                        <TableCell align="center"># Files</TableCell>
                        <TableCell align="center"># Errors</TableCell>
                        <TableCell align="center">Platform</TableCell>
                        <TableCell align="center">Timestamp</TableCell>
                        <TableCell align="center">Uploader</TableCell>
                        {/*<TableCell align="center">Upload Type</TableCell>*/}
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="right"></TableCell>
                        {/*<TableCell align="right"></StyledTableCell>*/}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (rowsPerPage > 0
                                ?
                            rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                :
                            rows
                        ).map((upload) => (
                            //Inserting a Row of Content
                            <UploadContent key={upload.id} upload={upload} setReportToInspect={props.setReportToInspect}/>
                        ))
                    }

                    {
                        emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={10} />
                            </TableRow>
                        )
                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                            colSpan={10}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            // ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    );
}

const useRowStyles = {
    root: {
        userSelect: "none",
        cursor: 'pointer',
        '& > *': {
            borderBottom: 'unset',
        },
    },
    fileInfoTable: {
        paddingLeft: "0px",
        paddingRight: "0px",
        '& > *': {

            backgroundColor: '#f4f1f1',
        }
    },
    cell: {
        '& > * th': {
            borderBottom: 'unset',
        }
    }
};

// UploadContent.propTypes = {
//     row: PropTypes.shape({
//         reportId: PropTypes.string.isRequired,
//         num_of_uploaded_files: PropTypes.number.isRequired,
//         num_of_errors: PropTypes.number.isRequired,
//         platform: PropTypes.string.isRequired,
//         timestamp: PropTypes.string.isRequired,
//         uploader: PropTypes.string.isRequired,
//         report_status: PropTypes.string.isRequired,
//         showButton: PropTypes.element.isRequired,
//         downloadButton: PropTypes.element.isRequired,
//         file_infos: PropTypes.arrayOf(
//             PropTypes.shape({
//                 filename: PropTypes.string.isRequired,
//             }),
//         ).isRequired
//     }).isRequired,
// };



class DropdownInspectButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            upload: this.props.upload,
            handleDownload: this.props.handleDownload,
            disabled: this.props.disabled,
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("Update state", prevProps, this.props, prevState, this.state)
    }

    onChange = isOpen => {
        // console.log("Set open to", isOpen);
        this.setState({
            isOpen
        });
    };

    handleClick = (uploadId, streamType) => {
        console.log("UploadID", uploadId)
        this.state.handleDownload(uploadId, streamType);
        this.setState({isOpen: !this.state.isOpen});
    };


    render() {
        const { isOpen } = this.state;
        const { disabled } = this.state;
        const { upload } = this.props;

        // console.warn("ISOPEN", isOpen);

        return(
            <DropDown
                ref={ref => this.dropdownRef = ref}
                // setState={state => this.setState(state)}
                isOpen={isOpen}
                onClick={this.onChange}
                disabled={disabled}
            >
                {
                    upload.file_infos.length > 1 ?
                        <React.Fragment>
                            <div onClick={() => {this.handleClick(upload.id, StreamType.UPLOADED_FILES)}}>
                                <i className="fas fa-file-archive"></i>
                                <span>Uploads .zip</span>
                            </div>
                            <div onClick={() => this.handleClick(upload.id, StreamType.PROCESSED_REPORT)}>
                                <i className="fas fa-file-archive"></i>
                                <span>Processed Reports .zip</span>
                            </div>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div onClick={() => this.handleClick(upload.id, StreamType.UPLOADED_FILES)}>
                                <i className="fas fa-file-upload"></i>
                                <span>Uploads</span>
                            </div>
                            <div onClick={() => this.handleClick(upload.id, StreamType.PROCESSED_REPORT)}>
                                <i className="fas fa-file-download"></i>
                                <span>Processed Reports</span>
                            </div>
                        </React.Fragment>
                }
            </DropDown>
        )
    }

}



function UploadContent(props){

    const api = new API();

    const { upload } = props;
    console.log("UPLOAD", upload, props)

    const [open, setOpen] = React.useState(false);
    const [opendd, setOpendd] = React.useState(false);

    const classes = useRowStyles;

    const handleInspection = (reportId) => {
        // console.log("INSPECT REPORT WITH ID " + reportId)
        api.inspect.report.get(api.getCookie("jwt"), reportId).then((response) => {
            // console.log("Report Inspection Response ", response)

            props.setReportToInspect(reportId, response.data);

        }).catch((error) => {

        })
    }

    const getStatusName = (status) => {
        switch (status) {
            case "READY_FOR_PROCESS": return "Ready to be processed";
            case "NOT_READY_FOR_PROCESS": return "Not Ready";
            case "PROCESSING": return "Processing...";
            case "ERROR": return "Error";
            case "DONE": return "Done";
        }
    }

    const handleDownload = (reportId, streamType) => {
        // console.log("Downloading Report with Id", reportId, api)
        api.downloads.get(api.getCookie("jwt"), reportId, streamType).then(response => {
            if (!(response.data instanceof Blob)) return;
            else{
                const blob = new Blob([response.data], { type: 'application/excel' });
                let disposition = response.headers["content-disposition"]
                let filename;
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }).catch((error) => {
            console.error(error)
        })

    }

    const getErrorReportId = (error_report, reportStatus) => {
        if(error_report){
            return error_report.id;
        }else if(reportStatus === "READY_FOR_PROCESS"){
            return "RE need to process Reports";
        }else if(reportStatus === "PROCESSING"){
            return "RE is processing Upload";
        }else{
            return "Error?!"
        }
    }

    const openCollapse = (elem) => {
        // console.log(elem.nativeEvent.path)
        let isButtonGroup = false;
        for(let elements of elem.nativeEvent.path){
            // console.log(elements.classList)
            if(elements.classList && elements.classList.contains("MuiButtonGroup-root"))
                isButtonGroup = true;
        }
        if(isButtonGroup == false)
            setOpen(!open)
    }

    return(
        <React.Fragment>
            <TableRow key={upload.id} className={classes.root} hover={true} onClick={openCollapse} style={{zIndex:10}}>
                <TableCell style={{width: 20}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell style={{ width: 20  }} align="center"> <StatusDot className={upload.report_status}/>  </TableCell>

                {/*<TableCell style={{ width: 200 }} component="th" scope="row"> {upload.id} </TableCell>*/}
                {/*<TableCell style={{ width: 200 }} component="th" scope="row"> {getErrorReportId(upload.error_report, upload.report_status)} </TableCell>*/}
                <TableCell style={{ width: 100 }} align="center">{ (upload.file_infos != null) ? upload.file_infos.length : "" }</TableCell>
                <TableCell style={{ width: 100 }} align="center"> {upload.num_of_errors} </TableCell>
                <TableCell style={{ width: 100 }} align="center"> {upload.platform} </TableCell>
                <TableCell style={{ width: 200 }} align="center"> {new Date(upload.timestamp).toLocaleString()} </TableCell>
                <TableCell style={{ width: 220 }} align="center">
                    <div style={{display: "flex", alignItems: "center", gap: "6px"}}>
                        {upload.uploader}
                        {(upload.upload_type ? <UploadTypeTag type={upload.upload_type}>{upload.upload_type}</UploadTypeTag> : "")}
                    </div>
                </TableCell>
                {/*<TableCell style={{ width: 120, textAlign: '-webkit-center'}} align="center">  </TableCell>*/}
                <TableCell style={{ width: 160 }} align="left"> {getStatusName(upload.report_status)} </TableCell>

                {
                    (upload.report_status === 'READY_FOR_PROCESS' || upload.report_status === "DONE")
                        ?
                        <React.Fragment>
                            <TableCell style={{ width: 200 }} align="left">
                                <ButtonGroup id="buttonGroup">
                                    {
                                        !upload.error_report && upload.report_status === 'READY_FOR_PROCESS'
                                        ?
                                            <InspectButton disabled={true}>
                                                <i className="fas fa-search"></i>
                                                <span>Show</span>
                                            </InspectButton>
                                        :
                                        upload.error_report && upload.report_status === 'DONE'
                                        ?
                                        <InspectButton onClick={() => handleInspection(upload.id)} >
                                            <i className="fas fa-search"></i>
                                            <span>Show</span>
                                        </InspectButton>
                                        :
                                        <InspectButton disabled={true} error={true}>
                                            <i className="fas fa-exclamation-triangle"></i>
                                            <span>Error</span>
                                        </InspectButton>
                                    }


                                    {/*{*/}
                                    {/*    upload.report_status === 'DONE' && upload.error_report*/}
                                    {/*        ?*/}
                                    {/*        <InspectButton onClick={() => handleInspection(upload.id)} >*/}
                                    {/*            <i className="fas fa-search"></i>*/}
                                    {/*            <span>Show</span>*/}
                                    {/*        </InspectButton>*/}
                                    {/*        :*/}
                                    {/*        <InspectButton disabled={true}>*/}
                                    {/*            <i className="fas fa-exclamation-triangle"></i>*/}
                                    {/*            <span>Error</span>*/}
                                    {/*        </InspectButton>*/}
                                    {/*}*/}

                                    {
                                        upload.error_report && upload.report_status === 'DONE'
                                            ?
                                            <DropdownInspectButton
                                                isOpen={opendd}
                                                upload={upload}
                                                handleDownload={handleDownload}
                                            />
                                            :
                                            <></>
                                    }



                                    {/*<InspectButton onClick={() => handleDownload(upload.id, "UPLOAD")} >*/}
                                    {/*    {*/}
                                    {/*        upload.file_infos.length > 1 ?*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                <i className="fas fa-file-archive"></i>*/}
                                    {/*                <span>Download .zip</span>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*            :*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                <i className="fas fa-file-download"></i>*/}
                                    {/*                <span>Download Uploads</span>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*    }*/}

                                    {/*</InspectButton>*/}
                                    {/*<InspectButton onClick={() => handleDownload(upload.id, "DOWNLOAD")} >*/}
                                    {/*    {*/}
                                    {/*        upload.file_infos.length > 1 ?*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                <i className="fas fa-file-archive"></i>*/}
                                    {/*                <span>Download .zip</span>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*            :*/}
                                    {/*            <React.Fragment>*/}
                                    {/*                <i className="fas fa-file-download"></i>*/}
                                    {/*                <span>Download Reports</span>*/}
                                    {/*            </React.Fragment>*/}
                                    {/*    }*/}
                                    {/*</InspectButton>*/}
                                </ButtonGroup>

                            </TableCell>
                            {/*<TableCell style={{ width: 140 }} align="left">*/}
                            {/*    */}
                            {/*</TableCell>*/}
                        </React.Fragment>

                        :
                        <React.Fragment>
                            <TableCell></TableCell>
                            {/*<TableCell></TableCell>*/}
                        </React.Fragment>

                }
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}  className={classes.fileInfoTable}>
                    {/*    Collapsible Content*/}
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2} >
                            <Typography variant="h6" gutterBottom component="div">
                                RuleEngine
                            </Typography>

                            {
                                (upload.rule_engine_message)
                                    ?
                                    <React.Fragment>
                                        <i className="fas fa-exclamation-triangle" style={{color: 'red', paddingRight: '6px'}}></i>
                                        <label style={{color: 'red'}}>
                                            {upload.rule_engine_message}
                                        </label>
                                    </React.Fragment>
                                    :
                                    <p style={{color: 'lightgrey'}}>No message available</p>
                            }

                            <Typography variant="h6" gutterBottom component="div">
                                List of all uploaded Files
                            </Typography>
                            <Table size="small" aria-label="filenames">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>File Name</TableCell>
                                        <TableCell>Size</TableCell>
                                        <TableCell>Uploaded Time</TableCell>
                                        {/*<TableCell align="center">Download</TableCell>*/}
                                        {/*<TableCell align="right">Amount</TableCell>*/}
                                        {/*<TableCell align="right">Total price ($)</TableCell>*/}
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.cell}>

                                    {
                                        (upload.file_infos != null ) ?
                                            upload.file_infos.map((fileInfo) => (
                                            <TableRow key={fileInfo.file_hash}>
                                                <TableCell component="th" scope="row" align="left" style={{width: 350}}>
                                                    {fileInfo.file_name}
                                                </TableCell>
                                                <TableCell component="th" scope="row" align="left" style={{width: 100}}>
                                                    {(fileInfo.file_size/1024).toFixed(1)+"kB"}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {new Date(fileInfo.file_upload_time).toLocaleString()}
                                                </TableCell>
                                                {/*<TableCell component="th" align="center">*/}
                                                {/*    <InspectButton onClick={() => handleDownload(upload.id)} >*/}
                                                {/*        <i className="fas fa-file-download"></i>*/}
                                                {/*        <span>Download</span>*/}
                                                {/*    </InspectButton>*/}
                                                {/*</TableCell>*/}
                                                {/*<TableCell>{historyRow.customerId}</TableCell>*/}
                                                {/*<TableCell align="right">{historyRow.amount}</TableCell>*/}
                                                {/*<TableCell align="right">*/}
                                                {/*    {Math.round(historyRow.amount * row.price * 100) / 100}*/}
                                                {/*</TableCell>*/}
                                            </TableRow>
                                            ))
                                            :
                                            <></>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

