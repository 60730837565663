import React, {Component, useEffect, useState} from "react";
import styled from "styled-components";
import {StreamType} from "./InspectReports";
import API from "../../../api/api";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import Table from "@material-ui/core/Table";

import {
    Box,
    Collapse,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableFooter,
    Table,
    TablePagination,
    IconButton,
} from "@mui/material";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import '../../global/css/ErrorReport.css';
import {Tab, Tabs} from "react-bootstrap";
// import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import TablePagination from "@material-ui/core/TablePagination";
// import TableFooter from "@material-ui/core/TableFooter";
// import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import {toast} from "react-toastify";
import Modal from "../../modal/Modal";
import {AlertWarningWithDissmiss} from "../../utils/Warnings";
import {CardDiv} from "./InspectReport.styles";
import {convertDate, renderCategoryTypeIcon} from "../../utils/GlobalUtils";
import {TablePaginationActions} from "./UploadHistoryTable";
// import Alert from '@material-ui/lab/Alert';

export const ErrorReportContentDiv = styled.div`
  //border: 1px solid black;
  width: 100%;
  padding: 8px;
  //text-align: center;
  
  h1{
    text-align: center;
  }
  
`;

const PropertyDiv = styled.div`
  
  display: flex;
  flex-direction: row;
  
  border: 1px solid black;
  
  :hover{
    background-color: lightgray;
  }
  
`;

const ErrorReportHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  height: 40px;
  margin-bottom: 12px;
  
  label{
    font-weight: bold;
    margin-bottom: unset;
  }
  
  button{
    color: white;
    background-color: #0677a1;
    border: unset;
    height: 40px;
    padding: 0px 12px;
    span{
      padding-left: 6px;
    }
    
    :hover{
      background-color: #3498DB;
    }
    
  }
  
`;

const ReturnButton = styled.button`

  border: none;
  display: flex;
  padding: 8px 16px;
  
  :hover{
    background-color: lightgray;
  }
  span{
    padding-left: 8px;
  }
  
`;

const CategoryDiv = styled.div`
  display: flex;
  align-items: baseline;
  i{
    width: 20px;
  }
  label{
    width: 120px;
  }
  
`;

const Overlay = styled.div`
  position: absolute; /* Sit on top of the page content */
  //display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  
  align-items: center;
  text-align: center;
  
  display: flex;
  align-content: center;
  justify-content: center;
  
  div[class="OverlayContent"]{
    button{
      color: #fff!important;
      background-color: #2196F3!important;
      
      i{
        padding-right: 10px;
      }
    }
  }
  
`;

class ErrorReport extends Component {

    constructor(props) {
        super(props);

        this.state = {
            report: this.props.report,
            uploadId: this.props.reportUploadId,
            refreshReport: this.props.refreshReport,
            selectedTab: "success"
        }

        this.api = new API();

        // this.handleShowErrors = this.handleShowErrors.bind(this);
    }

    renderTableHeader = (type) => {

        if(type === "APPROVED")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader">
                        <TableCell>Type</TableCell>
                        <TableCell>Property</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
            )
        if(type === "REJECTED")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader">
                        <TableCell></TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Property</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Error Category</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
            )
        if(type === "AWAIT")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader">
                        <TableCell>Type</TableCell>
                        <TableCell>Property</TableCell>
                        <TableCell>Period</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
            )
    }

    handleShowErrors = (uploadId, reportId, propertyInfos) => {

        let propertyForErrors = {
            uploadId: uploadId,
            reportId: reportId,
            propertyId: propertyInfos.property.id,
            propertyType: propertyInfos.type,
            propertyStatus: propertyInfos.status,
        }
        console.table(propertyForErrors)

        this.api.inspect.property.get(this.api.getCookie("jwt"), propertyForErrors).then((response) => {
            console.log(response)
        }).catch((error) => {

        })

    }

    render() {
        let {report, refreshReport} = this.state;

        // console.log(report)

        let reports_APPROVED = report.properties.filter((property) => property.status.includes("APPROVED"));
        let reports_REJECTED_BY_SYSTEM = report.properties.filter((property) => property.status.includes("REJECTED_BY_SYSTEM"));
        let reports_REJECTED_BY_USER = report.properties.filter((property) => property.status.includes("REJECTED_BY_USER"));
        let reports_AWAIT = report.properties.filter((property) => property.status.includes("AWAIT"));

        // console.log(reports_APPROVED, reports_REJECTED_BY_SYSTEM, reports_AWAIT)

        let reuploadAlert = "";
        if(reports_REJECTED_BY_USER.length > 0)
            reuploadAlert = <i className="fas fa-exclamation-triangle" style={{color: "red"}}></i>

        return(
            <ErrorReportContentDiv>
                <h1>Error Report View</h1>
                <hr/>
                <ReturnButton onClick={() => this.props.backToOverview()}>
                    <ArrowBackIcon/>
                    <span>Back to Overview</span>
                </ReturnButton>
                <hr/>
                {
                    report.warnings.map((warning) => {
                        return(<AlertWarningWithDissmiss>{warning}</AlertWarningWithDissmiss>)
                    })
                }
                <CardDiv style={{padding: 12}}>

                    <ErrorReportHeader>
                        <label>Report-ID {report.id}</label>
                        {/*<button onClick={() => this.props.downloadReport(this.state.uploadId, StreamType.PROCESSEDREPORT)}> Download Reports</button>*/}
                        <button onClick={() => this.props.downloadReport(this.state.uploadId, StreamType.PROCESSED_REPORT)}>
                            <i className="fas fa-file-archive"></i>
                            <span>Download Reports</span>
                        </button>

                    </ErrorReportHeader>

                    <Tabs
                        id="statusTabs"
                        activeKey={this.state.selectedTab}
                        onSelect={(k) => this.setState({ selectedTab: k })}
                        className="mb-2 statusTabs"
                    >
                        <Tab eventKey="success" title={"Success (" + reports_APPROVED.length + ")" }>
                            <Table>
                                {this.renderTableHeader("APPROVED")}
                                <TableBody>
                                    {
                                        // report.properties.length == 0
                                        reports_APPROVED.length == 0
                                        ?
                                            <TableRow>
                                                <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={4}> No approved properties found! </TableCell>
                                            </TableRow>
                                        : reports_APPROVED.map((propertyInfos) => {
                                                return(
                                                    <ReportContent
                                                        uploadId={this.state.uploadId}
                                                        report={report}
                                                        propertyInfos={propertyInfos}
                                                        refreshReport={refreshReport}
                                                    ></ReportContent>
                                                );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Tab>
                        <Tab eventKey="failed" title={"Failed (" + reports_REJECTED_BY_SYSTEM.length + ")" }>
                            <Table>
                                {this.renderTableHeader("REJECTED")}
                                <TableBody>
                                    {
                                        reports_REJECTED_BY_SYSTEM.map((propertyInfos) => {
                                                return(
                                                    <ReportContent
                                                        uploadId={this.state.uploadId}
                                                        report={report}
                                                        propertyInfos={propertyInfos}
                                                        refreshReport={refreshReport}
                                                    >
                                                    </ReportContent>
                                                );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Tab>
                        <Tab eventKey="awaiting" title={"In Review (" + reports_AWAIT.length + ")" }>
                            <Table>
                                {this.renderTableHeader("AWAIT")}
                                <TableBody>
                                    {
                                        reports_AWAIT.map((propertyInfos) => {
                                            return(
                                                <ReportContent
                                                    uploadId={this.state.uploadId}
                                                    report={report}
                                                    propertyInfos={propertyInfos}
                                                    refreshReport={refreshReport}></ReportContent>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Tab>
                        {/*<Tab eventKey="reupload" title={"Re-Upload necessary (" + reports_REJECTED_BY_USER.length + ")" }>*/}
                        <Tab eventKey="reupload" title={<span>{reuploadAlert} Re-Upload necessary ({reports_REJECTED_BY_USER.length})</span>}>
                            <Table>
                                {this.renderTableHeader("REJECTED")}
                                <TableBody>
                                    {
                                        reports_REJECTED_BY_USER.map((propertyInfos) => {
                                            return(
                                                <ReportContent
                                                    uploadId={this.state.uploadId}
                                                    report={report}
                                                    propertyInfos={propertyInfos}
                                                    refreshReport={refreshReport}></ReportContent>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Tab>
                    </Tabs>
                </CardDiv>
            </ErrorReportContentDiv>

        )
    }
}

function ReportContent(props){

    let{uploadId, report, propertyInfos, refreshReport} = props;

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const [errors, setErrors] = useState([]);
    const [exceptionFulfilled, setExceptionFulfilled] = useState(null);
    const [open, setOpen] = useState(false);

    const api = new API();



    const getTableRows = (errors) => {
        // console.log(errors)

        let rows = [];
        errors.map((error) => {
            // console.log(error)
            rows.push({
                id: error.id,
                table_name: error.table_name,
                field_name: error.field_name,
                line_number: error.line_number,
                error_description: error.error_description,
                error_type: error.error_type,
                exception: error.exception,
                exception_status: error.exception_status,
            });
        })
        rows.sort((a, b) => (a.line_number < b.line_number ? -1 : 1));

        return rows;
    }

    const renderCategories = (stats) => {
        return(
            <React.Fragment>
                {
                    (stats.logical_check > 0) ?
                        <CategoryDiv>
                            <i className="fas fa-puzzle-piece"></i>
                            <label>Logical Check</label>
                            <span>{stats.logical_check}</span>
                        </CategoryDiv>
                        :
                        <></>
                }
                {
                    (stats.smart_check > 0) ?
                        <CategoryDiv>
                            <i className="far fa-lightbulb"></i>
                            <label>Smart Check</label>
                            <span>{stats.smart_check}</span>
                        </CategoryDiv>
                        : ""
                }
                {
                    (stats.history_check > 0) ?
                        <CategoryDiv>
                            <i className="far fa-calendar-check"></i>
                            <label>History Check</label>
                            <span>{stats.history_check}</span>
                        </CategoryDiv>
                        : ""
                }
                {
                    (stats.technical_check > 0) ?
                        <CategoryDiv>
                            <i className="fas fa-cog"></i>
                            <label>Technical Check</label>
                            <span>{stats.technical_check}</span>
                        </CategoryDiv>
                        : ""
                }
            </React.Fragment>
        )
    }

    /**
     * Receives all Errors for uploadId, reportId and PropertyInfo
     * @param uploadId
     * @param reportId
     * @param propertyInfos
     */
    const handleShowErrors = (uploadId, reportId, propertyInfos) => {

        let propertyForErrors = {
            uploadId: uploadId,
            reportId: reportId,
            propertyId: propertyInfos.property.id,
            propertyType: propertyInfos.type,
            propertyStatus: propertyInfos.status,
        }
        console.table(propertyForErrors)

        if(!open){
            api.inspect.property.get(api.getCookie("jwt"), propertyForErrors).then((response) => {
                let errors = response.data;
                setErrors(errors)
                setOpen(!open)
                if(checkExceptionFullfillment(errors)){
                    setExceptionFulfilled(true)
                }else{
                    setExceptionFulfilled(false)
                }

            }).catch((error) => {
                console.log(error)
                // toast.error(error.response.data)
            })

        }else{
            setOpen(!open)
        }
    }

    const checkExceptionFullfillment = (errors) => {
        let exceptions = errors.filter((error) => error.exception)
        return errors.length === exceptions.length
    }

    const updateError = (error) => {
        let newErrors = [...errors];
        for(let idx in errors){
            if(errors[idx].id === error.id) {
                newErrors[idx] = error;
            }
        }
        setErrors(newErrors)
        if(checkExceptionFullfillment(newErrors)){
            setExceptionFulfilled(true)
        }else{
            setExceptionFulfilled(false)
        }
    }

    const sendToAssetManager = (uploadId, report, propertyInfos) => {
        console.log("Send to asset manager", uploadId, report, propertyInfos)
        console.log(uploadId)
        console.log(report)
        console.log(propertyInfos)
        // report = null;

        /**
         * uploadId,
         report.id,
         propertyInfos.property.id,
         propertyInfos.type,
         report.period,
         propertyInfos.status
         * @type {{}}
         */

        let notificationObject = {}
        notificationObject.uploadId = uploadId;
        notificationObject.reportId = report.id;
        notificationObject.propertyId = propertyInfos.property.id;
        notificationObject.propertyType = propertyInfos.type;
        notificationObject.propertyPeriod = report.period;
        notificationObject.propertyStatus = propertyInfos.status;

        api.notify.am(api.getCookie("jwt"), notificationObject).then((response) => {
            if(response.status === 200 && response.data !== "")
                props.refreshReport();
        }).catch((error) => {
            console.error(error)
            toast.error(error);
        })
    }


    //TODO: Remove this after finished Exceptions
    const setAllExceptions = async(uploadId, reportId, period, propertyInfos) => {

        console.log("Setting all exceptions")

        let propertyForErrors = {
            uploadId: uploadId,
            reportId: reportId,
            propertyId: propertyInfos.property.id,
            propertyType: propertyInfos.type,
            propertyStatus: propertyInfos.status,
        }

        let response = await api.inspect.property.get(api.getCookie("jwt"), propertyForErrors);
        let errors = response.data;
        // console.log(errors);

        for(let error of errors) {
            // console.log(error)
            if(!error.exception){
                let exceptionObject = {}

                exceptionObject.property_id = propertyInfos.property.id;
                exceptionObject.property_type = propertyInfos.type;
                exceptionObject.property_period = period;
                exceptionObject.property_status = propertyInfos.status;
                exceptionObject.error_id = error.id;
                exceptionObject.exception_note_pm = "Automated Exception";

                let response = await api.inspect.property.exception.newPropertyException(api.getCookie("jwt"), exceptionObject);
                console.log(response)

            }
            else
                console.log("Exception", error)
        }

        //Request backend again if all Exceptions where wrote
        response = await api.inspect.property.get(api.getCookie("jwt"), propertyForErrors);
        errors = response.data;
        if(checkExceptionFullfillment(errors)){
            console.log("All exception where wrote")
            setExceptionFulfilled(true)
        }else{
            setExceptionFulfilled(false)
        }
    }

    const render = () => {
        // console.log("RENDER")
        if(propertyInfos.status.includes("APPROVED") || propertyInfos.status.includes("AWAIT")){
            return (
                <TableRow key={propertyInfos.property.id} className="tableRow">
                    <TableCell style={{width: 60}}> {renderCategoryTypeIcon(propertyInfos.type)} </TableCell>
                    <TableCell> {propertyInfos.property.property_id_sender}</TableCell>
                    <TableCell> {convertDate(report.period)}</TableCell>
                    <TableCell> {propertyInfos.status}</TableCell>
                </TableRow>
            )
        }
        else if(propertyInfos.status.includes("REJECTED_BY_SYSTEM")) {

            // console.log("Render" , errors)

            let rows = getTableRows(errors);
            const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

            // console.log("ExceptionFulFilled", exceptionFulfilled)

            return (
                <React.Fragment>
                    <TableRow key={propertyInfos.property.id} className="tableRow propertyInfo" onClick={() => handleShowErrors(uploadId, report.id, propertyInfos)}>
                        <TableCell style={{width: 20}}>
                            <IconButton aria-label="expand row" size="small">
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>

                        <TableCell style={{width: 60}}> {renderCategoryTypeIcon(propertyInfos.type)} </TableCell>
                        <TableCell> {propertyInfos.property.property_id_sender}</TableCell>
                        <TableCell> {convertDate(report.period)}</TableCell>
                        <TableCell> {renderCategories(propertyInfos.stats)} </TableCell>
                        <TableCell> {propertyInfos.status}</TableCell>
                        <TableCell>
                            {
                                (exceptionFulfilled != null)
                                    ?
                                    (exceptionFulfilled === true)
                                        ?
                                        <button className="w3-btn w3-blue" style={{width: 'fit-content'}} onClick={ () => sendToAssetManager( uploadId, report, propertyInfos ) }>
                                            <i className="fas fa-upload" aria-hidden="true"></i>
                                            Send to Asset Manager
                                        </button>
                                        :
                                        <button
                                            style={{zIndex: 3}}
                                            onClick={() => setAllExceptions(uploadId, report.id, report.period, propertyInfos)}
                                        >
                                            Set all Exceptions
                                        </button>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                            {/*<button*/}
                            {/*    style={{zIndex: 3}}*/}
                            {/*    onClick={() => setAllExceptions(uploadId, report.id, report.period, propertyInfos)}*/}
                            {/*>*/}
                            {/*    Set all Exceptions*/}
                            {/*</button>*/}
                        </TableCell>
                    </TableRow>

                    {
                        (errors !== null) ?
                            <TableRow className="tableRow">
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>

                                    {/*<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}  className={classes.fileInfoTable}>*/}
                                    {/*    Collapsible Content*/}
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        {/*{(checkExceptionFullfillment(errors) ? setExceptionFullfilled(true) : setExceptionFullfilled(false))}*/}

                                        {/*{*/}
                                        {/*    (checkExceptionFullfillment(errors)) ?*/}
                                        {/*        <Overlay>*/}
                                        {/*            <div className="OverlayContent">*/}
                                        {/*                <button className="w3-btn w3-blue" onClick={*/}
                                        {/*                    () => sendToAssetManager(*/}
                                        {/*                        uploadId,*/}
                                        {/*                        report,*/}
                                        {/*                        propertyInfos*/}
                                        {/*                    )*/}
                                        {/*                }>*/}
                                        {/*                    <i className="fas fa-upload" aria-hidden="true"></i>*/}
                                        {/*                    Send to Asset Manager*/}
                                        {/*                </button>*/}
                                        {/*            </div>*/}
                                        {/*        </Overlay>*/}
                                        {/*        :*/}
                                        {/*        <></>*/}
                                        {/*}*/}
                                        <Box margin={2} >

                                            <Table size="small" aria-label="filenames">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        {/*<TableCell>Error ID</TableCell>*/}
                                                        <TableCell>Table</TableCell>
                                                        <TableCell>Field</TableCell>
                                                        <TableCell>Line</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell align="right"></TableCell>
                                                        <TableCell align="right" style={{width:0}}></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {/*<TableBody>*/}
                                                {/*    {errors.map((error) => (*/}
                                                {/*        <ErrorContent error={error} propertyInfos={propertyInfos} report={report} updateError={updateError}></ErrorContent>*/}
                                                {/*    ))}*/}
                                                {/*</TableBody>*/}
                                                <TableBody>
                                                    {
                                                        (rowsPerPage > 0
                                                                ?
                                                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                :
                                                                rows
                                                        ).map((error, key) => (
                                                            <ErrorContent
                                                                key={key}
                                                                error={error}
                                                                propertyInfos={propertyInfos}
                                                                report={report}
                                                                updateError={updateError}
                                                                api={api}
                                                            ></ErrorContent>
                                                            // <ErrorContent key={key} error={error} propertyInfos={propertyInfos} report={report} updateError={updateError}></ErrorContent>
                                                        ))
                                                    }

                                                    {
                                                        emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={9} />
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>
                                                <TableFooter>
                                                    <TableRow>
                                                        <TablePagination
                                                            // id={"tablePagination"}
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                                                            colSpan={9}
                                                            count={rows.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: { 'aria-label': 'rows per page' },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            // ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            :
                            <></>
                    }

                </React.Fragment>
            )
        }
        else if(propertyInfos.status.includes("REJECTED_BY_USER")) {

            // console.log("Render" , errors)

            let rows = getTableRows(errors);
            const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

            return (
                <React.Fragment>
                    <TableRow key={propertyInfos.property.id} className="tableRow propertyInfo" onClick={() => handleShowErrors(uploadId, report.id, propertyInfos)}>
                        <TableCell style={{width: 20}}>
                            <IconButton aria-label="expand row" size="small">
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                        </TableCell>

                        <TableCell style={{width: 60}}> {renderCategoryTypeIcon(propertyInfos.type)} </TableCell>
                        <TableCell> {propertyInfos.property.property_id_sender}</TableCell>
                        <TableCell> {convertDate(report.period)}</TableCell>
                        <TableCell> {renderCategories(propertyInfos.stats)} </TableCell>
                        <TableCell> {propertyInfos.status}</TableCell>
                    </TableRow>

                    {
                        (errors !== null) ?
                            <TableRow className="tableRow">
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>

                                    {/*<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}  className={classes.fileInfoTable}>*/}
                                    {/*    Collapsible Content*/}
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <Box margin={2}>
                                            <Table size="small" aria-label="filenames">
                                                <TableHead>

                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                                                            colSpan={9}
                                                            count={rows.length}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            SelectProps={{
                                                                inputProps: { 'aria-label': 'rows per page' },
                                                                native: true,
                                                            }}
                                                            onPageChange={handleChangePage}
                                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                                            ActionsComponent={TablePaginationActions}
                                                        />
                                                    </TableRow>

                                                    <TableRow>
                                                        <TableCell></TableCell>
                                                        {/*<TableCell>Error ID</TableCell>*/}
                                                        <TableCell>Table</TableCell>
                                                        <TableCell>Field</TableCell>
                                                        <TableCell>Line</TableCell>
                                                        <TableCell>Description</TableCell>
                                                        <TableCell>Asset Manager</TableCell>
                                                        <TableCell>Note</TableCell>
                                                        <TableCell>Status</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        (rowsPerPage > 0
                                                                ?
                                                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                                :
                                                                rows
                                                        ).map((error, key) => (
                                                            <ErrorContent key={key} error={error} propertyInfos={propertyInfos} report={report} updateError={updateError} api={api}></ErrorContent>
                                                            // <ErrorContent key={key} error={error} propertyInfos={propertyInfos} report={report} updateError={updateError}></ErrorContent>
                                                        ))
                                                    }

                                                    {
                                                        emptyRows > 0 && (
                                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                                <TableCell colSpan={9} />
                                                            </TableRow>
                                                        )
                                                    }

                                                </TableBody>
                                                {/*<TableFooter>*/}
                                                {/*    <TableRow>*/}
                                                {/*        <TablePagination*/}
                                                {/*            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}*/}
                                                {/*            colSpan={9}*/}
                                                {/*            count={rows.length}*/}
                                                {/*            rowsPerPage={rowsPerPage}*/}
                                                {/*            page={page}*/}
                                                {/*            SelectProps={{*/}
                                                {/*                inputProps: { 'aria-label': 'rows per page' },*/}
                                                {/*                native: true,*/}
                                                {/*            }}*/}
                                                {/*            onPageChange={handleChangePage}*/}
                                                {/*            onRowsPerPageChange={handleChangeRowsPerPage}*/}
                                                {/*            ActionsComponent={TablePaginationActions}*/}
                                                {/*        />*/}
                                                {/*    </TableRow>*/}
                                                {/*</TableFooter>*/}
                                            </Table>
                                        </Box>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                            :
                            <></>
                    }

                </React.Fragment>
            )
        }
    }

    return render();
}

/**
 * Content of Error in several Tabs
 */
class ErrorContent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            error: this.props.error,
            propertyInfos: this.props.propertyInfos,
            report: this.props.report,
            // updateError: this.props.updateError,
            showExceptionModal: false,
            exceptionMessage: (this.props.error.exception ? this.props.error.exception.exception_note_pm : "") ,
        }

        this.api = props.api;

    }

    setErrorException = (propertyId, propertyType, propertyPeriod, propertyStatus, errorObject, type) => {
        console.log(propertyId, propertyType, propertyPeriod, propertyStatus, errorObject)

        let exceptionObject = {}

        // exceptionObject.property_id = this.state.propertyInfos.property.id;
        // exceptionObject.property_type = this.state.propertyInfos.type;
        // exceptionObject.property_period = this.state.report.period;
        // exceptionObject.property_status = this.state.propertyInfos.status;
        // exceptionObject.error_id = this.state.error.id;
        // exceptionObject.exception_note = this.state.exceptionMessage;

        exceptionObject.property_id = propertyId;
        exceptionObject.property_type = propertyType;
        exceptionObject.property_period = propertyPeriod;
        exceptionObject.property_status = propertyStatus;
        exceptionObject.error_id = errorObject.id;
        exceptionObject.exception_note_pm = this.state.exceptionMessage;

        if(type === "NEW"){
            this.api.inspect.property.exception.newPropertyException(this.api.getCookie("jwt"), exceptionObject).then((response) => {
                console.log(response)
                if(response.data.exception) {
                    let exception = response.data.exception;
                    // setException(response.data.exception);
                    // console.log(exception)
                    this.props.updateError(response.data);
                    toast.success("Saved Exception", {position: toast.POSITION.BOTTOM_CENTER})
                    this.setState({showExceptionModal: !this.state.showExceptionModal})
                }
            }).catch((error) => {
                console.error(error)
            })
        }else if(type === "UPDATE"){
            this.api.inspect.property.exception.updatePropertyException(this.api.getCookie("jwt"), exceptionObject).then((response) => {
                console.log(response)
                if(response.data.exception) {
                    let exception = response.data.exception;
                    // setException(response.data.exception);
                    // console.log(exception)
                    this.props.updateError(response.data);
                    toast.success("Saved Exception", {position: toast.POSITION.BOTTOM_CENTER})
                    this.setState({showExceptionModal: !this.state.showExceptionModal})
                }
            }).catch((error) => {
                console.error(error)
            })
        }

    }

    setExceptionMessage = (textarea) => {
        console.log(textarea.target.value)
        // exceptionMessage = textarea.target.value;
        this.setState({exceptionMessage: textarea.target.value})
        if(this.props.error.exception)
            this.props.error.exception.exception_note_pm = textarea.target.value

        console.log(this.props.error.exception)
    }

    getExceptionStatus = (exception_status) => {
        if(exception_status === "REJECTED") return <span style={{color: 'red'}}>REJECTED</span>
        else if(exception_status === "ACCEPTED") return <span style={{color: 'green'}}>APPROVED</span>
        else if(exception_status === "PENDING") return <span style={{color: 'lightblue'}}>PENDING</span>
    }

    render() {

        let {propertyInfos, report, error} = this.props;

        // if(error.exception)
        //     console.log(error.exception, propertyInfos);

        return(
            <TableRow key={error.id} className={(error.exception !== null) ? "hasException" : ""}>
                <TableCell component="td" scope="row" align="left" style={{width: 50}}>
                    {renderErrorTypeIcon(error.error_type)}
                </TableCell>
                {/*<TableCell>{error.id}</TableCell>*/}
                <TableCell>{error.table_name}</TableCell>
                <TableCell>{error.field_name}</TableCell>
                <TableCell>{error.line_number}</TableCell>
                <TableCell>{error.error_description}</TableCell>
                {
                    (error.exception && error.exception.exception_writer_am)
                        ?
                        <React.Fragment>
                            <TableCell>{error.exception.exception_writer_am.email}</TableCell>
                            <TableCell>{error.exception.exception_note_am}</TableCell>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </React.Fragment>
                }

                <TableCell style={{minWidth: 150}}>
                    {
                        (propertyInfos.status === "REJECTED_BY_USER")
                            ?
                            this.getExceptionStatus(error.exception.exception_status)
                            :
                            (error.exception !== null)
                                ?
                                <button className={"editExceptionButton"} onClick={() => this.setState({showExceptionModal: !this.state.showExceptionModal})}>
                                    <i className="fas fa-edit"></i>
                                    Edit Exception
                                </button>
                                :
                                <button className={"exceptionButton"} onClick={() => this.setState({showExceptionModal: !this.state.showExceptionModal})}>
                                    <i className="far fa-plus-square"></i>
                                    Set Exception
                                </button>
                    }
                </TableCell>
                <TableCell style={{padding: 0}}>
                        <Modal
                            show={this.state.showExceptionModal}
                            handleClose={() => this.setState({showExceptionModal: !this.state.showExceptionModal})}
                            showCloseButton={true}
                            headerText="New Exception"
                            customStyle={"customModal"}
                            showLogout={false}
                        >
                            <div className="exceptionModal">
                                <label>Exception ID</label> <span>{error.id}</span>
                                <label>Error type</label> {renderErrorTypeIcon(error.error_type)}
                                <label>Table</label><span>{error.table_name}</span>
                                <label>Field</label><span>{error.field_name}</span>
                                <label>Line</label><span>{error.line_number}</span>
                                <label>Description</label><span style={{color: "red"}}>{error.error_description}</span>
                                <label>Table</label><span>{error.table_name}</span>
                                {
                                    (this.props.error.exception)
                                        ?
                                        <React.Fragment>
                                            <textarea name="exceptionMessage" onChange={this.setExceptionMessage} value={error.exception.exception_note_pm}></textarea>
                                            <button
                                                onClick={() =>
                                                    this.setErrorException(
                                                        propertyInfos.property.id,
                                                        propertyInfos.type,
                                                        report.period,
                                                        propertyInfos.status,
                                                        error,
                                                        "UPDATE"
                                                    )
                                                }>
                                                Update Exception
                                            </button>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <textarea name="exceptionMessage" onChange={this.setExceptionMessage}></textarea>
                                            <button
                                                onClick={() =>
                                                    this.setErrorException(
                                                        propertyInfos.property.id,
                                                        propertyInfos.type,
                                                        report.period,
                                                        propertyInfos.status,
                                                        error,
                                                        "NEW"
                                                    )
                                                }>
                                                Submit Exception
                                            </button>
                                        </React.Fragment>
                                }

                            </div>

                        </Modal>
                </TableCell>
            </TableRow>
        )
    }

}

export const renderErrorTypeIcon = (error_type) => {
    // console.log(error_type);
    switch(error_type) {
        case "logical_check":   return <i className="fas fa-puzzle-piece"/>
        case "smart_check":     return <i className="far fa-lightbulb"/>
        case "history_check":   return <i className="far fa-calendar-check"/>
        case "technical_check": return <i className="fas fa-cog"/>
        default: return <></>

    }
}

export default ErrorReport;
