import styled from 'styled-components';

export const ContentCard = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  p{
    color: grey;
  }
`;

export const ContentDiv = styled.div`
  align-self: center;
  width: 720px;

`;
export const StyledExportButton = styled.button`
    /* make text all caps*/
    text-transform: uppercase;
    /* rounded borders*/
    border-radius: 4px;
    /* padding */
    padding: 8px 16px;
    /* font color white */
    color: white;
    /* border none, shaddow */
    border: none;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    
    background-color: #1976d2;
    ${props => props.status === "loading" ? "background-color: #1976d266;" : ""}
`

export const DataExportForm = styled.form`
  text-align: left;
  display: grid;
  grid-template-columns: 30% 70%;
  row-gap: 2em;
  align-items: center;
  //border: 1px solid black;
  border-radius: 16px;
  padding: 12px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background-color: #f2f2f2;
  //background-color: lightgrey;
  //background-color: #7ac142;
  //padding: 12px;  
  
`;

export const PeriodFilterStyle = (options) => {
    return {
        control: (baseStyles, state) => ({
            ...baseStyles,
            // borderRadius: "0px",
            backgroundColor: "unset",
            // border: "unset",
            borderColor: "black",
            color: "black",
            // width: "160px",
            // margin: "8px",
            minWidth: "max-content",
            opacity: (options.length === 0) ? 0.5 : 1
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 10000,
            width: "160px",
            minWidth: "min-content"
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: "black"
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: "black"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px 12px"
        })
    }
}